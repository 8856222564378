import { Card, Col, Row, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const JsonToString = () => {
    const [json, setJson] = useState<Object>()
    const [msg, setMsg] = useState<any>()

    return (<div className="json-body" key="json-view">
        <br />
        <Row>
            <Col span={24}>
                <TextArea
                    size="large"
                    placeholder="JSON转字符串"
                    onChange={(e) => {
                        const value = e.target.value
                        if (value) {
                            try {
                                const doStr = JSON.parse(value)
                                const jss = JSON.parse(doStr)
                                setJson(jss)
                            } catch (e) {
                                message.error(new String(e))
                            }
                        } else {
                            message.error('内容不可以为空！')
                        }
                    }}
                />
            </Col>
        </Row>
        <br />
        <Row>
            {msg && <Col span={24}>
                <Card style={{ color: 'red' }}>
                    {msg}
                </Card>
            </Col>}
        </Row>
        <br />
        <Row>
            {json && <Col span={24}>
                <Card
                    hoverable
                    className="local-weather"
                >
                    {JSON.stringify(json)}
                </Card>
            </Col>}
        </Row>
    </div>)
}

export default JsonToString