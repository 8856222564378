import { BarsOutlined, BranchesOutlined, HomeOutlined } from "@ant-design/icons"
import { FC, useState } from "react"
import Page from "../../components/Page"
import './index.scss'
import Api from "./Api"

const PostApi: FC = (props) => {
    const [children, setChildren] = useState<React.ReactNode>()

    return <Page siderOnClick={setChildren}
        siders={[{label:'接口调用', key:'api', child: <Api />}]}
        paths={[{ title: <HomeOutlined />, href: '/', }, { title: <BranchesOutlined />, href: '/api', }]}
        children={children} />
}

export default PostApi