import React, { useState } from 'react';
import JSEncrypt from 'jsencrypt'
import { Button, Divider, Typography } from 'antd';

const { Text } = Typography

const GenerateKeyPair = () => {
  const [publicKey, setPublicKey] = useState('')
  const [privateKey, setPrivateKey] = useState('')

  const generateKeyPair = () => {

    // 生成RSA密钥对
    const rsa = new JSEncrypt();
    const keypair = rsa.getKey();

    // 获取公钥和私钥
    const publicKey = keypair.getPublicKey();
    const privateKey = keypair.getPrivateKey();
    setPrivateKey(privateKey)
    setPublicKey(publicKey)
  };

  return (
    <div>
      <Button type='primary' onClick={generateKeyPair}>生成密钥对</Button>
      <Divider>私钥</Divider>
      <Typography><Text copyable>{privateKey}</Text></Typography>
      <Divider>公钥</Divider>
      <Typography><Text copyable>{publicKey}</Text></Typography>
    </div>
  );
}

export default GenerateKeyPair;