import { SearchOutlined } from "@ant-design/icons"
import { Button, Card, Col, Descriptions, Row, Skeleton, theme } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import { geoApi, weatherApi } from "../../../../service/amap/api"

const QueryWeather = () => {
    const [localWeather, setLocalWeather] = useState<Weather.Weather>()
    const [isLoding, setIsLoding] = useState<boolean>(false)
    const [address, setAddress] = useState<string>()

    const {
        token: { colorSuccessText },
    } = theme.useToken();

    useEffect(() => {

    }, [])

    const request = () => {
        geoApi({ address }).then(res => {
            if (res.data.code === '00000') {
                console.log(res.data.data.geocodes[0].adcode)
                if (res.data.data.geocodes && res.data.data.geocodes.length) {
                    weatherApi({ city: res.data.data.geocodes[0].adcode }).then(res2 => {
                        if (res2.data.code === '00000') {
                            setLocalWeather(res2.data.data)
                        }
                        setIsLoding(false)
                    })
                }
            }
        })
    }

    return (<div className="weather-body" key="query-weather">
        <br />
        <TextArea
            maxLength={50}
            style={{ height: 30, width: 230, resize: 'none' }}
            placeholder="输入地址，xxx市"
            onChange={(e) => {
                setAddress(e.target.value)
            }}
        />
        <br />
        <br />
        <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<SearchOutlined />} loading={isLoding} onClick={request} >{isLoding ? '正在查询...请您耐心等待～' : '查询天气'}</Button>
        <br />
        <br />
        <Row>
            {isLoding && <Skeleton active />}
            {localWeather?.forecasts.length && <Col span={24}>
                <Card
                    hoverable
                    className="local-weather"
                >
                    <Descriptions title={localWeather.forecasts[0].city + '今日天气'}>
                        <Descriptions.Item label="日期">{localWeather?.forecasts[0].casts[0].date}</Descriptions.Item>
                        <Descriptions.Item label="星期">{localWeather?.forecasts[0].casts[0].week}</Descriptions.Item>
                        <Descriptions.Item label="日间天气">{localWeather?.forecasts[0].casts[0].dayweather}</Descriptions.Item>
                        <Descriptions.Item label="夜间天气">{localWeather?.forecasts[0].casts[0].nightweather}</Descriptions.Item>
                        <Descriptions.Item label="日间气温">{localWeather?.forecasts[0].casts[0].daytemp + '度'}</Descriptions.Item>
                        <Descriptions.Item label="夜间气温">{localWeather?.forecasts[0].casts[0].nighttemp + '度'}</Descriptions.Item>
                        <Descriptions.Item label="日间风向">{localWeather?.forecasts[0].casts[0].daywind + '风'}</Descriptions.Item>
                        <Descriptions.Item label="日间风力">{localWeather?.forecasts[0].casts[0].daypower + '级'}</Descriptions.Item>
                        <Descriptions.Item label="夜间风向">{localWeather?.forecasts[0].casts[0].nightwind + '风'}</Descriptions.Item>
                        <Descriptions.Item label="夜间风力">{localWeather?.forecasts[0].casts[0].nightpower + '级'}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>}
        </Row>
        <br />
        <Row justify="space-between">
            {localWeather?.forecasts.length && localWeather.forecasts[0].casts.map((item, index) => (index > 0 && <>
                <Col span={8}>
                    <Card
                        hoverable
                        className="local-weather"
                    >
                        <Descriptions title={item.date}>
                            <Descriptions.Item label="星期">{item.week}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.dayweather}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nightweather}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.daytemp + '度'}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nighttemp + '度'}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.daywind + '风'}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.daypower + '级'}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nightwind + '风'}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nightpower + '级'}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </>))}
        </Row>
    </div>)
}

export default QueryWeather