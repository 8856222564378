import { apiRequest } from "../../utils/ApiRequest"

/**
 * 天气查询
 * @param params 
 * @returns 
 */
export const weatherApi = <T>(params: T) => {
    return apiRequest('/amap/weather', 'POST', params)
}

/**
 * 地理查询
 * @param params 
 * @returns 
 */
export const geoApi = <T>(params: T) => {
    return apiRequest('/amap/geo', 'POST', params)
}

/**
 * IP地址查询信息
 * @param params 
 * @returns 
 */
export const ipApi =  <T>(params: T) => {
    return apiRequest('/amap/ip', 'POST', params)
}

/**
 * 获取本机IP地址信息
 * @param params 
 * @returns 
 */
export const localIpApi = () => {
    return apiRequest('/amap/local-ip', 'POST', {})
}
