import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import VConsole from 'vconsole';
import './index.scss';
import Ip from './pages/Ip';
import Json from './pages/Json';
import Regexp from './pages/Regexp';
import Weather from './pages/Weather';
import Base64 from './pages/Base64';
import PostApi from './pages/PostApi';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const vConsole = new VConsole()

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<Ip />} />
        <Route path="/ip" element={<Ip/>} />
        <Route path="/weather" element={<Weather />} />
        <Route path="/json" element={<Json/>} />
        <Route path="/regexp" element={<Regexp/>} />
        <Route path="/base64" element={<Base64/>} />
        <Route path="/api" element={<PostApi/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
