import { Button, Checkbox, Divider, InputNumber, Space, Typography } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useState } from "react";
import { generatePassword } from "../../../utils/ToolsUtils";
const { Title, Paragraph, Text, Link } = Typography;

const PswGenerate = () => {
    const [length, setLength] = useState<any>(6)
    const [psw, setPsw] = useState<string | null>()
    const [checkedValue, setCheckedValue] = useState<CheckboxValueType[]>(['x', 'd', 'n', 't'])

    const options = [
        { label: '包含小写字母', value: 'x' },
        { label: '包含大写字母', value: 'd' },
        { label: '包含数字', value: 'n' },
        { label: '包含特殊字符', value: 't' },
    ];

    const find = (arrs: CheckboxValueType[], value: any) => {
        let rs = false
        arrs.forEach(item => {
            if (item === value) {
                console.log(item)
                rs = true
            }
        })
        return rs
    }

    return <>
        <Checkbox.Group options={options} defaultValue={['x', 'd', 'n', 't']} onChange={setCheckedValue} />
        <Divider />
        <Space>
            <InputNumber min={6} max={99} value={length} onChange={setLength} />
            <Button
                type="primary"
                onClick={() => {
                    console.log(length)
                    const psw = generatePassword(length, find(checkedValue, 'x'), find(checkedValue, 'd'), find(checkedValue, 't'), find(checkedValue, 'n'))
                    setPsw(psw)
                }}
            >
                生成
            </Button>
        </Space>
        <Divider />
        <Typography><Text copyable>{psw}</Text></Typography>
    </>
}

export default PswGenerate