import axios from "axios";
import { timestamp } from "./DateUtils";
import { getApiHost } from "./HostUtils";

/**
 * 统一网络请求入口
 * @param props 
 * @returns 
 */
export const apiRequest = <T>(uri: string,
    method: 'GET' | 'POST',
    params: T) => {
    // 设置超时时间
    axios.defaults.timeout = 120000
    // 获取当前服务端host
    axios.defaults.baseURL = getApiHost()
    return axios({
        url: uri,
        method,
        params: { ...params, timestamp: timestamp() },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer UX3$$WJV^fTBmpJhUuWlebUPJ@JcZ!Iv',
        }
    })
}