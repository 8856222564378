import { Breadcrumb } from "antd"
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { FC } from "react"

type BreadcrumbState = {
    items: ItemType[];
}

const BreadcrumbTsx: FC<BreadcrumbState> = (props) => {

    const { items } = props

    return (<Breadcrumb style={{ margin: '16px 0' }}
        items={items}
    />)
}
export default BreadcrumbTsx