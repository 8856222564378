import { Button, Input, Select, Space, Tabs, TabsProps, Tag } from "antd"
import { useState } from "react"
import ParamsForm from "./ParamsForm";
import axios, { AxiosResponse } from "axios";
import './index.scss'
import { GlobalOutlined } from "@ant-design/icons";
import ResponseStatus from "./ResponseStatus";

const Api = () => {
    const [params, setParams] = useState<any>({})
    const [protocol, setProtocol] = useState<'https' | 'http'>("http")
    const [url, setUrl] = useState<string>('')
    const [uri, setUri] = useState<string>('')
    const [method, setMethod] = useState<'GET' | 'POST'>('GET')
    const [response, setResponse] = useState<AxiosResponse<any, any>>()
    const [time, setTime] = useState<number>()
    const [size, setSize] = useState<number>()

    const onChange = (key: string) => {
        console.log(key);
    }

    const options = [
        {
            value: 'POST',
            label: 'POST',
        },
        {
            value: 'GET',
            label: 'GET',
        },
    ];

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Params',

            children: <ParamsForm setParams={setParams} />,
        },
        {
            key: '2',
            label: 'Authorization',
            children: 'developing...',
        },
        {
            key: '3',
            label: 'Headers',
            children: 'developing...',
        },
        {
            key: '4',
            label: 'Body',
            children: 'developing...',
        }
    ]

    const bodyItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Pretty',
            children: <div>{JSON.stringify(response?.data)}</div>,
        },
        {
            key: '2',
            label: 'Raw',
            children: 'developing...',
        },
        {
            key: '3',
            label: 'Preview',
            children: 'developing...',
        },
        {
            key: '4',
            label: 'Visualize',
            children: 'developing...',
        }
    ]

    const responseItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Body',
            children: <Tabs
                onChange={onChange}
                type="card"
                items={bodyItems}
            />
        },
        {
            key: '2',
            label: 'Cookies',
            children: 'developing...',
        },
        {
            key: '3',
            label: 'Headers',
            children: 'developing...',
        }
    ]




    return <>
        <div className="api-page">
            <div className="api-page-url">
                <Tag icon={<GlobalOutlined />} color="#55acee" onClick={() => {
                    if (protocol === 'http') {
                        setProtocol('https')
                    }
                    if (protocol === 'https') {
                        setProtocol('http')
                    }
                }}>
                    {protocol}
                </Tag>{url}
            </div>
            <div className="api-page-url-input">
                <Space direction="vertical">
                    <Space.Compact>
                        <Select defaultValue='GET' options={options} onChange={(value: 'POST' | 'GET') => setMethod(value)} />
                        <Input placeholder="url" style={{ width: '460px' }} onChange={e => {
                            const value = e.target.value
                            if (/^https:\/\//gi.test(value)) {
                                setProtocol('https')
                            }
                            if (/^http:\/\//gi.test(value)) {
                                setProtocol('http')
                            }
                            setUri(value.replace(/^http:\/\//gi, '').replace(/^https:\/\//gi, ''))
                        }} />
                        <Button onClick={() => {
                            let url2 = protocol + '://' + uri
                            // url2 = url2 + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')
                            setUrl(url2)
                            let startTime = 0
                            // 添加一个请求拦截器
                            axios.interceptors.request.use((request) => {
                                // 在发送请求之前做某件事
                                console.log('请求开始')
                                startTime = new Date().getTime()
                                return request
                            }, function (error) {
                                // 对请求错误做些什么
                                console.log(error);
                                return Promise.reject(error);
                            });

                            // 添加一个响应拦截器
                            axios.interceptors.response.use((response) => {
                                // 对响应数据做点什么
                                const endTime = new Date().getTime()
                                const usedTime = endTime - startTime
                                console.log(`请求耗时：${usedTime}ms`)
                                setTime(usedTime)
                                setSize(JSON.stringify(response.data).length)
                                return response
                            }, (error) => {
                                // 对响应错误做点什么
                                console.log(error);
                                return Promise.reject(error);
                            });
                            axios({
                                url: 'https://api.qiyuyouyun.com//api-agent/result',
                                method: 'get',
                                params: {
                                    url: url2,
                                    method: method,
                                    params: JSON.stringify(params)
                                },
                            }).then(
                                res => {
                                    setResponse(res)
                                    console.log(res)
                                }
                            ).catch(
                                res => {
                                    console.log(res)
                                    setResponse(res.response)
                                }
                            )
                        }}>发送</Button>
                    </Space.Compact>
                </Space>
            </div>
            <div className="api-page-params">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
            <div className="api-page-response">
                <Tabs defaultActiveKey="1" items={responseItems} onChange={onChange} tabBarExtraContent={{ right: response && <ResponseStatus size={size} time={time} status={response?.status}/> }} />
            </div>
        </div>
    </>
}

export default Api