import { BlockOutlined, HomeOutlined } from "@ant-design/icons"
import { FC, useState } from "react"
import Page from "../../components/Page"
import JsonView from "./JsonView"
import './index.scss'
import JsonCompress from "./JsonCompress"
import RemoveEscape from "./RemoveEscape"
import JsonToString from "./JsonToString"


const Json: FC = () => {

    const [children, setChildren] = useState<React.ReactNode>()


    return <Page siderOnClick={setChildren}
        siders={[
            { label: 'JSON可视化', key: 'json-view', child: <JsonView /> },
            { label: 'JSON压缩', key: 'json-compress', child: <JsonCompress /> },
            { label: '字符串转JSON', key: 'remove-escape', child: <RemoveEscape /> },
            { label: 'JSON转字符串', key: 'json-to-string', child: <JsonToString /> }
        ]}
        paths={[
            { title: <HomeOutlined />, href: '/', },
            {
                title: <BlockOutlined />, href: '/json',
            }]}
        children={children} />
}

export default Json