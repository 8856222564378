
export const isMobile = () => {
    return document.body.clientWidth < 750
}

/**
 * IPv4地址正则
 * @returns 
 */
export const ipReg = () => {
    return /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/
}

/**
 * 判断是否是json
 * @param str 
 * @returns 
 */
export const isJSON = (str: any) => {
    if (typeof str == 'string') {
        try {
            const obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            console.log(e);
            return false;
        }
    }
    console.log('It is not a string!')
}


/**
 * 密码生成器
 * @param length 生成长度
 * @param useLowerCase 是否使用小写字母
 * @param useUpperCase 是否使用大写字母
 * @param useSpecialChar 是否使用特殊字符
 * @param useNumber 是否包含数字
 * @returns 生成的密码
 */
export const generatePassword = (length: any, useLowerCase: boolean, useUpperCase: boolean, useSpecialChar: boolean, useNumber: boolean) => {
    let password = "";
    const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
    const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    const numbers = "0123456789";

    if (useLowerCase) {
        password += lowerCaseChars;
    }
    if (useUpperCase) {
        password += upperCaseChars;
    }
    if (useSpecialChar) {
        password += specialChars;
    }
    if (useNumber) {
        password += numbers;
    }
    if (!password) {
        return null;
    }

    const charsLength = password.length;

    let rsPassword = "";

    for (let i = 0; i < length; i++) {
        rsPassword += password.charAt(Math.floor(Math.random() * charsLength));
    }
    return rsPassword;
}