import { HomeOutlined, UngroupOutlined } from "@ant-design/icons"
import { FC, useState } from "react"
import Page from "../../components/Page"
import './index.scss'
import Base64Encryption from "./Base64Encryption"
import Base64Decrypt from "./Base64Decrypt"
import PswGenerate from "./PswGenerate"
import Rsa from "./Rsa"


const Base64: FC = () => {

    const [children, setChildren] = useState<React.ReactNode>()


    return <Page siderOnClick={setChildren}
        siders={[
            { label: 'Base64加密', key: 'encryption', child: <Base64Encryption /> },
            { label: 'Base64解密', key: 'json-compress', child: <Base64Decrypt /> },
            { label: '密码生成器', key: 'psw-generate', child: <PswGenerate /> },
            { label: 'RSA生成', key: 'rsa', child: <Rsa /> },
        ]}
        paths={[
            { title: <HomeOutlined />, href: '/', },
            {
                title: <UngroupOutlined />, href: '/base64',
            }]}
        children={children} />
}

export default Base64