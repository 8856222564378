import { Card, Col, Descriptions, Row, Skeleton } from "antd"
import { useEffect, useState } from "react"
import { localIpApi, weatherApi } from "../../../../service/amap/api"

const LocalWeather = () => {
    const [weather, setWeather] = useState<Weather.Weather>()

    useEffect(() => {
        localIpApi().then(res => {
            if (res.data.code === '00000') {
                console.log(res.data.data.adcode)
                weatherApi({ city: res.data.data.adcode }).then(res2 => {
                    if (res2.data.code === '00000') {
                        setWeather(res2.data.data)
                    }
                })
            }
        })
    }, [])

    return (<div className="weather-body">
        <br />
        <Row>
            {!weather?.forecasts.length && <Skeleton active />}
            {weather?.forecasts.length && <Col span={24}>
                <Card
                    hoverable
                    className="local-weather"
                >
                    <Descriptions title={weather.forecasts[0].city + '今日天气'}>
                        <Descriptions.Item label="日期">{weather?.forecasts[0].casts[0].date}</Descriptions.Item>
                        <Descriptions.Item label="星期">{weather?.forecasts[0].casts[0].week}</Descriptions.Item>
                        <Descriptions.Item label="日间天气">{weather?.forecasts[0].casts[0].dayweather}</Descriptions.Item>
                        <Descriptions.Item label="夜间天气">{weather?.forecasts[0].casts[0].nightweather}</Descriptions.Item>
                        <Descriptions.Item label="日间气温">{weather?.forecasts[0].casts[0].daytemp + '度'}</Descriptions.Item>
                        <Descriptions.Item label="夜间气温">{weather?.forecasts[0].casts[0].nighttemp + '度'}</Descriptions.Item>
                        <Descriptions.Item label="日间风向">{weather?.forecasts[0].casts[0].daywind + '风'}</Descriptions.Item>
                        <Descriptions.Item label="日间风力">{weather?.forecasts[0].casts[0].daypower + '级'}</Descriptions.Item>
                        <Descriptions.Item label="夜间风向">{weather?.forecasts[0].casts[0].nightwind + '风'}</Descriptions.Item>
                        <Descriptions.Item label="夜间风力">{weather?.forecasts[0].casts[0].nightpower + '级'}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Col>}
        </Row>
        <br />
        <Row justify="space-between">
            {weather?.forecasts.length && weather.forecasts[0].casts.map((item, index) => (index > 0 && <>
                <Col span={8}>
                    <Card
                        hoverable
                        className="local-weather"
                    >
                        <Descriptions title={item.date}>
                            <Descriptions.Item label="星期">{item.week}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.dayweather}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nightweather}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.daytemp + '度'}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nighttemp + '度'}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.daywind + '风'}</Descriptions.Item>
                            <Descriptions.Item label="日间">{item.daypower + '级'}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nightwind + '风'}</Descriptions.Item>
                            <Descriptions.Item label="夜间">{item.nightpower + '级'}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </>))}
        </Row>
    </div>)
}

export default LocalWeather