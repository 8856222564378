
import { BarsOutlined, BlockOutlined, BranchesOutlined, CloudOutlined, EnvironmentOutlined, UngroupOutlined } from "@ant-design/icons"
import { Menu, MenuProps } from "antd"
import { Header } from "antd/es/layout/layout"
import { MenuItemType } from "antd/es/menu/hooks/useItems"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import './index.scss'

export const HeaderTsx = () => {
  const [current, setCurrent] = useState('index');
  const navigate = useNavigate()

  const items: MenuItemType[] = [{
    label: 'IP地址查询',
    key: '/ip',
    icon: <EnvironmentOutlined />,

  }, {
    label: '天气查询',
    key: '/weather',
    icon: <CloudOutlined />,
  }, {
    label: 'JSON在线',
    key: '/json',
    icon: <BlockOutlined />,
  }, {
    label: '正则表达式',
    key: '/regexp',
    icon: <BarsOutlined />,
  }, {
    label: '加解密工具',
    key: '/base64',
    icon: <UngroupOutlined />,
  }, {
    label: '接口调用',
    key: '/api',
    icon: <BranchesOutlined />,
  }]

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    navigate(e.key)
  };


  return (
    <Header className="header" style={{ background: 'white' }}>
      <div className="logo" />
      <Menu theme="light" mode="horizontal" onClick={(e) => { onClick(e) }} defaultSelectedKeys={['2']} selectedKeys={[current]} items={items} />
    </Header>
  )
}