import { Menu, theme } from "antd"
import Sider from "antd/es/layout/Sider"
import { FC, useEffect, useState } from "react";

type SiderMenuState = {
    menus: { label: string, key: string, child: React.ReactNode }[];
    onClick: (children: React.ReactNode) => void
}

const SiderMenu: FC<SiderMenuState> = (props) => {
    const { menus, onClick } = props
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        if (menus) {
            if (menus.length > 0) {
                if (menus[0]) {
                    if (menus[0].key) {
                        setSelectedKeys([menus[0].key])
                        onClick(menus[0].child)
                    }
                }
            }
        }
    }, [])

    const click = (info: any) => {
        const { item, key } = info
        setSelectedKeys([key])
        onClick(item.props.child)
    }

    return (<Sider style={{ background: colorBgContainer }} width={200}>
        <Menu
            mode="inline"
            onClick={click}
            selectedKeys={selectedKeys}
            style={{ height: '100%' }}
            items={menus}
        />
    </Sider>)
}

export default SiderMenu