import { SearchOutlined } from "@ant-design/icons"
import { Button, message, Table, theme } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useState } from "react"
import { ipQueryApi } from "../../../../service/Ip/api"
import { ipReg } from "../../../../utils/ToolsUtils"

const IpQuery = () => {
    const [ip, setIp] = useState<string>('')
    const [isLoding, setIsLoding] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<Array<any>>([])
    const {
        token: { colorSuccessText },
    } = theme.useToken();

    const columns = [
        {
            title: 'IP地址',
            dataIndex: 'ip',
            key: 'ip',
        },
        {
            title: '运营商',
            dataIndex: 'isp',
            key: 'isp',
        },
        {
            title: '国家',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: '省',
            dataIndex: 'province',
            key: 'province',
        },
        {
            title: '市',
            dataIndex: 'city',
            key: 'city',
        },
    ];

    const request = () => {
        if (ip.trim() === '') {
            message.error('请输入您的IP地址！')
            return;
        }
        if (!ipReg().test(ip.trim())) {
            message.error('IP地址格式错误！')
            return;
        }
        setIsLoding(true)
        ipQueryApi({ ip }).then(
            res => {
                if (res.data.code === '00000') {
                    setDataSource([res.data.data])
                }
                setIsLoding(false)
            }
        ).catch(() => {
            message.error('思考似乎出了些问题～请您调整一下问题描述重试！')
            setIsLoding(false)
        });

    }

    return (<div className='ip-body' key="ip-query">
        <br />
        <TextArea
            maxLength={50}
            style={{ height: 30, width: 230, resize: 'none' }}
            placeholder="查询IP地址"
            onChange={(e) => {
                setIp(e.target.value)
            }}
        />
        <br />
        <br />
        <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<SearchOutlined />} loading={isLoding} onClick={request} >{isLoding ? '正在查询...请您耐心等待～' : '查询IP地址'}</Button>
        <br />
        <br />
        <Table dataSource={dataSource} columns={columns} loading={isLoding} pagination={false} />
    </div>)
}

export default IpQuery