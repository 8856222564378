import React, { useState } from 'react';
import { EnvironmentOutlined, HomeOutlined } from '@ant-design/icons';
import Page from '../../components/Page';
import LocalIp from './Components/LocalIp';
import './index.scss'
import IpQuery from './Components/IpQuery';


const Ip: React.FC = () => {

    const [children, setChildren] = useState<React.ReactNode>()

    return (
        <Page siderOnClick={setChildren} siders={[{ label: '本机IP信息', key: 'local-ip', child: <LocalIp /> }, { label: '查询IP信息', key: 'query-ip', child: <IpQuery /> }]} paths={[{ title: <HomeOutlined />, href: '/', }, { title: <EnvironmentOutlined />, href: '/ip', }]} children={children} />
    );
};

export default Ip;
