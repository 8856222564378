import { CloudOutlined, HomeOutlined } from "@ant-design/icons"
import { FC, useState } from "react"
import Page from "../../components/Page"
import LocalWeather from "./Components/LocalWeather"
import QueryWeather from "./Components/QueryWeather"
import './index.scss'

const Weather: FC = (props) => {
    const [children, setChildren] = useState<React.ReactNode>()

    return <Page siderOnClick={setChildren}
        siders={[{ label: '本地天气', key: 'local-weather', child: <LocalWeather /> }, { label: '天气查询', key: 'query-weather', child: <QueryWeather /> }]}
        paths={[{ title: <HomeOutlined />, href: '/', }, { title: <CloudOutlined />, href: '/weather', }]}
        children={children} />
}

export default Weather