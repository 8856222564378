import { Card, Col, Row, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import CryptoJs from 'crypto-js'

const { Text } = Typography;

const Base64Encryption = () => {
    const [content, setContent] = useState<string>()

    return (<div className="json-body" key="json-compress">
        <br />
        <Row>
            <Col span={24}>
                <TextArea
                    size="large"
                    placeholder="输入要加密的字符串"
                    onChange={(e) => {
                        const value = e.target.value.trim()
                        console.log(value)
                        setContent(CryptoJs.enc.Base64.stringify(CryptoJs.enc.Utf8.parse(value)))
                        console.log(CryptoJs.enc.Base64.stringify(CryptoJs.enc.Utf8.parse(value)))
                    }}
                />
            </Col>
        </Row>
        <br />
        <Row>
            {content && <Col span={24}>
                <Card
                    hoverable
                    className="local-weather"
                >
                    <Typography> <Text copyable>{content}</Text></Typography>
                </Card>
            </Col>}
        </Row>
    </div>)
}

export default Base64Encryption