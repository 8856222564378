import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';

type ParamsFormState = {
  setParams: (params: any) => void
}

const ParamsForm: React.FC<ParamsFormState> = (porps) => {
  const { setParams } = porps
  const [form] = Form.useForm()


  return (
    <Form
      form={form}
      name="params-form"
      style={{ maxWidth: 600 }}
      autoComplete="off"
      onChange={(e) => {
        const params: any = {}
        form.getFieldsValue().params?.forEach((item: any) => {
          params[item.key] = item.value
        })
        setParams(params)
      }}
    >
      <Form.List name="params">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  rules={[{ required: true, message: 'Missing key' }]}
                >
                  <Input placeholder="key" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  <Input placeholder="value" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}

export default ParamsForm;