import { Card, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import ReactJson from "react-json-view";

const JsonView = () => {
    const [json, setJson] = useState<Object>()
    const [msg, setMsg] = useState<any>()

    return (<div className="json-body" key="json-view">
        <br />
        <Row>
            <Col span={24}>
                <TextArea
                    size="large"
                    placeholder="Json可视化"
                    onChange={(e) => {
                        const value = e.target.value.trim()
                        if (typeof value == 'string') {
                            try {
                                const obj = JSON.parse(value);
                                if (typeof obj == 'object' && obj) {
                                    setJson(JSON.parse(value))
                                    setMsg(undefined)
                                    return;
                                } else {
                                    setJson(undefined)
                                    setMsg('不是JSON字符串')
                                    return;
                                }
                            } catch (e) {
                                setJson(undefined)
                                setMsg(new String(e))
                                return;
                            }
                        }
                    }}
                />
            </Col>
        </Row>
        <br />
        <Row>
            {msg && <Col span={24}>
                <Card style={{ color: 'red' }}>
                    {msg}
                </Card>
            </Col>}
        </Row>
        <br />
        <Row>
            {json && <Col span={24}>
                <Card
                    hoverable
                    className="local-weather"
                >
                    <ReactJson src={json} theme="monokai" />
                </Card>
            </Col>}
        </Row>
    </div>)
}

export default JsonView