import { Card, Col, Row, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const { Title, Paragraph, Text, Link } = Typography;

const JsonCompress = () => {
    const [json, setJson] = useState<React.ReactNode>()
    const [msg, setMsg] = useState<any>()

    const compress = (jsonText: string) => {
        if (typeof jsonText == 'string') {
            try {
                const obj = JSON.parse(jsonText);
                if (typeof obj == 'object' && obj) {
                    setJson(jsonText.replaceAll(' ','').replaceAll(/(\n|\r|\r\n|↵)/g,''))
                    setMsg(undefined)
                    return;
                } else {
                    setJson(undefined)
                    setMsg('不是JSON字符串')
                    return;
                }
            } catch (e) {
                setJson(undefined)
                setMsg(new String(e))
                return;
            }
        }
    }

    return (<div className="json-body" key="json-compress">
        <br />
        <Row>
            <Col span={24}>
                <TextArea
                    size="large"
                    placeholder="输入要压缩的JSON串"
                    onChange={(e) => {
                        const value = e.target.value.trim()
                        compress(value)
                    }}
                />
            </Col>
        </Row>
        <br />
        <Row>
            {msg && <Col span={24}>
                <Card style={{ color: 'red' }}>
                    {msg}
                </Card>
            </Col>}
        </Row>
        <br />
        <Row>
            {json && <Col span={24}>
                <Card
                    hoverable
                    className="local-weather"
                >
                    <Typography> <Text copyable>{json}</Text></Typography>
                </Card>
            </Col>}
        </Row>
    </div>)
}

export default JsonCompress