import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";


const CommonReg = () => {
  const [content, setContent] = useState<string>('')
  useEffect(() => {
    fetch(require("./regexp.md")).then(res => {
      res.text().then(res2 => {
        setContent(res2)
      })
    });
  }, [])

  return (<ReactMarkdown >{content}</ReactMarkdown>)
}

export default CommonReg