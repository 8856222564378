import { Col, Descriptions, Row, Skeleton } from "antd"
import { useEffect, useState } from "react"
import { localQueryApi } from "../../../../service/Ip/api"

const LocalIp = () => {
    const [showLocal, setShowLocal] = useState<boolean>(false)
    const [localIp, setLocalIp] = useState<any>({})

    useEffect(() => {
        getLocal()
    }, [])

    const getLocal = () => {
        localQueryApi().then(
            res => {
                if (res.data.code === '00000') {
                    setLocalIp(res.data.data)
                    setShowLocal(true)
                }
            }
        )
    }
    return <div className='ip-body' key='local-ip'>{!showLocal && <Skeleton active />} {showLocal && <><br /><Row><Col span={24}><Descriptions bordered title="本机IP信息" size="small">
        <Descriptions.Item label="IP地址">{localIp?.ip}</Descriptions.Item>
        <Descriptions.Item label="运营商">{localIp?.isp}</Descriptions.Item>
        <Descriptions.Item label="国家">{localIp?.country}</Descriptions.Item>
        <Descriptions.Item label="省份">{localIp?.province}</Descriptions.Item>
        <Descriptions.Item label="城市">
            {localIp?.city}
        </Descriptions.Item>
    </Descriptions></Col></Row></>}
    </div>

}

export default LocalIp