import { BarsOutlined, HomeOutlined } from "@ant-design/icons"
import { FC, useState } from "react"
import Page from "../../components/Page"
import CommonReg from "./CommonReg"
// import './index.scss'

const Regexp: FC = (props) => {
    const [children, setChildren] = useState<React.ReactNode>()

    return <Page siderOnClick={setChildren}
        siders={[{ label: '常用正则表达式', key: 'common-reg', child: <CommonReg /> }]}
        paths={[{ title: <HomeOutlined />, href: '/', }, { title: <BarsOutlined />, href: '/regexp', }]}
        children={children} />
}

export default Regexp