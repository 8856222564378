import { Footer } from "antd/es/layout/layout"
import { useEffect } from "react";
import { qyyyConfig } from "../../qyyy.config";
import './index.scss'

export const FooterTsx = () => {

    useEffect(() => {
        if (qyyyConfig.https) {
            const href = window.location.href
            const isDevEnv = href.includes("172.16.") || href.includes("192.168.") || href.includes("localhost")
            if (!isDevEnv) {
                if (href.startsWith('http://')) {
                    window.location.href = window.location.href.replace('http://', 'https://')
                }
            }
        }
    }, [])

    return (<Footer className='footer' style={{ textAlign: 'center', lineHeight: '30px' }}>
        <div className='footer-wx'>
            {/* <img height={90} src={require('../../assets/gzh.png')}></img>
            <img height={90} src={require('../../assets/xcx.png')}></img> */}
        </div>
        {/* <div className='footer-logo'><img height={90} src={require('../../assets/footer-logo.png')}></img></div> */}
        <div className='copyright'>
            <div>Copyright © 2023 <a href="https://www.carpswim.com/">小川游鱼科技</a> 126开发者社区网V1.0.0 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022017516号</a></div>
            <div>起鱼有云提供计算服务</div>
        </div>
    </Footer>)
}