import React from 'react';
import { Layout, theme } from 'antd';
import { FooterTsx } from '../FooterTsx';
import { HeaderTsx } from '../HeaderTsx';
import BreadcrumbTsx from '../Breadcrumb';
import SiderMenu from '../SiderMenu';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

const { Content } = Layout;

type PageState = {
    paths: ItemType[];
    siders: { label: string, key: string, child: React.ReactNode }[];
    siderOnClick: (children: React.ReactNode) => void;
    children: React.ReactNode;
}

const Page: React.FC<PageState> = (props) => {

    const { children, paths, siders, siderOnClick } = props

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout key="main-page">
            <HeaderTsx />
            <Content style={{ padding: '0 50px' }}>
                <BreadcrumbTsx items={paths} />
                <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
                    <SiderMenu onClick={siderOnClick} menus={siders} />
                    <Content style={{ padding: '0 24px', minHeight: 280 }}>{children}</Content>
                </Layout>
            </Content>
            <FooterTsx />
        </Layout>
    );
};

export default Page;