import { apiRequest } from "../../utils/ApiRequest"

/**
 * IP查询接口
 * @param params 
 * @returns 
 */
export const ipQueryApi = <T>(params: T) => {
    return apiRequest('/ip/query', 'POST', params)
}

/**
 * 获取本机IP地址
 * @param params 
 * @returns 
 */
export const localQueryApi = () => {
    return apiRequest('/ip/local', 'POST', {})
}
