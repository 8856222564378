import { GlobalOutlined } from "@ant-design/icons"
import { FC } from "react"

type ResponseStatusState = {
    status?: number,
    size?: number,
    time?: number,
}

const ResponseStatus: FC<ResponseStatusState> = (props) => {
    const { status, size, time } = props

    return (<div style={{ display: 'flex', width: '260px', height: '100%', justifyContent: 'space-between', alignItems: 'center', fontSize: '12px' }}>
        <GlobalOutlined />
        <div>Status:<span style={{ color: status === 200 ? 'green' : 'red' }}>{status} {status === 200 ? 'OK' : 'Failed'}</span></div>
        <div>Time:<span style={{ color: 'green' }}>{time && time > 0 ? time : '--'} ms</span></div>
        <div>Size:<span style={{ color: 'green' }}>{size && size > 1000 ? Math.round(size / 1024).toFixed(2) : size} {size && size > 1000 ? 'Kb' : 'B'}</span></div>
    </div>)
}

export default ResponseStatus