import { qyyyConfig } from "../qyyy.config";

/**
 * 获取服务端接口IP
 * @returns 
 */
export const getApiHost = () => {
    let url = ''
    switch (qyyyConfig.env) {
        case 'dev':
            return qyyyConfig.devApiHost
        case 'daily':
            return qyyyConfig.dailyApiHost
        case 'prod':
            return qyyyConfig.prodApiHost
        default:
            const href = window.location.href
            if (href === 'localhost' || href.includes('172.16') || href.includes('192.168') || href.includes('10.')) {
                return qyyyConfig.devApiHost
            } else if (href.includes('-daily')) {
                return qyyyConfig.dailyApiHost
            } else if (href.includes('-prepub')) {
                return qyyyConfig.prodApiHost
            }
    }
    if (qyyyConfig.https) {
        if (url.startsWith('http://')) {
            url = url.replace('http://', 'https://')
        }
    }
    return url
}

/**
 * 获取PC端访问地址
 * @returns 
 */
export const getPcHost = () => {
    let url = ''
    switch (qyyyConfig.env) {
        case 'dev':
            url = qyyyConfig.devPcHost
            break;
        case 'daily':
            url = qyyyConfig.dailyPcHost
            break;
        case 'prod':
            url = qyyyConfig.prodPcHost
            break;
        default:
            const href = window.location.href
            if (href === 'localhost' || href.includes('172.16') || href.includes('192.168') || href.includes('10.')) {
                url = qyyyConfig.devPcHost
            } else if (href.includes('-daily')) {
                url = qyyyConfig.dailyPcHost
            } else if (href.includes('-prepub')) {
                url = qyyyConfig.prodPcHost
            }
    }
    if (qyyyConfig.https) {
        if (url.startsWith('http://')) {
            url = url.replace('http://', 'https://')
        }
    }
    return url
}

/**
 * 获取H5端访问地址
 * @returns 
 */
export const getMobileHost = () => {
    let url = ''
    switch (qyyyConfig.env) {
        case 'dev':
            url = qyyyConfig.devMobileHost
            break;
        case 'daily':
            url = qyyyConfig.dailyMobileHost
            break;
        case 'prod':
            url = qyyyConfig.prodMobileHost
            break;
        default:
            const href = window.location.href
            if (href === 'localhost' || href.includes('172.16') || href.includes('192.168') || href.includes('10.')) {
                url = qyyyConfig.devMobileHost
            } else if (href.includes('-daily')) {
                url = qyyyConfig.dailyMobileHost
            } else if (href.includes('-prepub')) {
                url = qyyyConfig.prodMobileHost
            }
    }
    if (qyyyConfig.https) {
        if (url.startsWith('http://')) {
            url = url.replace('http://', 'https://')
        }
    }
    return url
}